import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import ReactDelighters from 'rev.sdk.js/Components/ReactDelighters';
import Carousel from './BannerCarousel';
import SmallCarousel from '../../../Components/SmallCarousel';
import {ROOM_MOCK, SERVICE_MOCK, BREAKFAST_MOCK} from '../../../MockData';
import * as AppActions from '../../../AppActions';
import Foods from '../../Foods';

function Landing(props) {
  const {landing} = props;
  const [user] = useOutlet('user');
  const [dimension] = useOutlet('dimension');
  const [bannerList, setBannerList] = React.useState([]);

  const [articles, setArticles] = React.useState([]);
  const [bannerDimension, setBannerDimension] = React.useState(null);
  const [selectedRoomTypeIdx, setSelectedRoomTypeIdx] = React.useState(0);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchBannerList() {
      AppActions.setLoading(true);
      let bannerList = landing
        ? landing.custom_layout.hero_banner_section.images
        : [];
      setBannerList(bannerList);
      AppActions.setLoading(false);
    }
    fetchBannerList();

    async function fetchData() {
      try {
        AppActions.setLoading(true);
        let {results: _articles} = await AppActions.clientJStorageFetch(
          'Article_Default',
          {
            sort: '-created',
            page: {offset: 0, limit: 3},
          },
        );
        setArticles(_articles);
      } catch (ex) {
        console.warn(ex);
      } finally {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [landing]);

  let itemWidth = (dimension.innerWidth - 200 - 40) / 3;

  return (
    <ReactDelighters>
      <Wrapper dimension={dimension} id="rev-LandingPage">
        <div
          id="rev-banner"
          className="banner"
          style={{height: bannerDimension?.width * 0.38}}>
          <Carousel
            width={bannerDimension?.width}
            height={bannerDimension?.width * 0.38}
            items={bannerList}
            style={{position: 'absolute', top: 0, left: 0}}
          />
        </div>
        <section className="section-dark">
          <div className="subtitle">NEWS</div>
          <div className="title" style={{display: 'flex'}}>
            知知巢消息
            <div style={{flex: 1}} />
            <div
              className="read-more-btn"
              onClick={() => navigate('/articles')}>
              <p className="content" style={{fontWeight: 'lighter'}}>
                查看更多
              </p>
            </div>
          </div>
          <section
            style={{
              display: 'flex',
              flexDirection: dimension.rwd === 'desktop' ? 'row' : 'column',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            {articles.map((item, idx) => (
              <div
                onClick={() => {
                  navigate(`/article?id=${item.id}`);
                }}
                style={{
                  marginTop: 30,
                  marginBottom: 50,
                  cursor: 'pointer',
                  maxWidth:
                    dimension.rwd === 'desktop'
                      ? `${(dimension.innerWidth - 200 - 40) / 3}px`
                      : 'none',
                }}
                key={idx}>
                <div
                  className="grid-item-img"
                  style={{backgroundImage: `url(${item.cover || item.image})`}}
                />
                <p className="grid-item-date" style={{marginTop: 10}}>
                  {new Date(item.created).toLocaleString()}
                </p>
                <p className="grid-item-title">{item.title}</p>
                <p className="grid-item-outline" style={{marginTop: 10}}>
                  {item.outline}
                </p>
              </div>
            ))}
          </section>
        </section>

        <section className="section">
          <div className="subtitle">STAY WITH US</div>
          <div className="title" style={{display: 'flex'}}>
          住住好空間
            <div style={{flex: 1}} />
            <div className="read-more-btn" onClick={() => navigate('/rooms')}>
              <p className="content" style={{fontWeight: 'lighter'}}>
                查看更多
              </p>
            </div>
          </div>
        </section>
        <div className="scroll-constrain">
          <div className="scroll-wrapper">
            {ROOM_MOCK.map((room, idx) => (
              <div className="scroll-item">
                <div
                  className="scroll-item-img"
                  style={{backgroundImage: `url(${room.imagesArray[0]})`}}
                />
                <p className="scroll-item-title" style={{marginTop: 20}}>
                  {room.name}
                </p>
                <p className="scroll-item-subtitle" style={{marginBottom: 20}}>
                  {room.engName}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div
          id="rev-banner"
          className="banner"
          style={{height: bannerDimension?.width * 0.38}}>
          <img
            alt="service-banner"
            src="../../images/service-banner-4.png"
            style={{width: '100%', height: '100%', objectFit: 'cover'}}
          />
        </div>
        <section className="section-dark">
          <div className="subtitle">SERVICE</div>
          <div className="title">暖暖心服務</div>
          <div className="pj-row" style={{padding: '20px 0px'}}>
            {SERVICE_MOCK.map((room, idx) => (
              <div
                onClick={() => setSelectedRoomTypeIdx(idx)}
                className={`tab ${
                  selectedRoomTypeIdx === idx ? 'selected' : ''
                }`}>
                <p className="content">{room.name}</p>
              </div>
            ))}
          </div>
          <div className="carousel-wrapper">
            <div style={{flex: 1}}>
              <SmallCarousel
                width={
                  dimension.rwd === 'desktop'
                    ? dimension?.innerWidth / 2
                    : dimension?.innerWidth - 40
                }
                height={
                  dimension.rwd === 'desktop'
                    ? (dimension.innerWidth / 2) * 0.66
                    : dimension.innerWidth * 0.66
                }
                items={SERVICE_MOCK[selectedRoomTypeIdx].imagesArray}
              />
            </div>

            <div style={{flexBasis: 40}} />
            <div style={{flex: 1}}>
              <div className="title">
                {SERVICE_MOCK[selectedRoomTypeIdx].name}
              </div>
              {SERVICE_MOCK[selectedRoomTypeIdx].info.map((data, idx) => (
                <div>
                  <div className="subtitle" style={{marginTop: 20}}>
                    {data.title}
                  </div>
                  <p className="content">{data.content}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Foods showBanners={false} />
      </Wrapper>
    </ReactDelighters>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > section {
    position: relative;
    overflow-x: hidden;
  }

  & > .banner {
    width: 100%;
    height: 600px;
    position: relative;
  }

  & .landing-slide-in-right {
    opacity: 0;
    transform: translateX(2000px);
    transition: 350ms;
  }

  & .landing-slide-in-right.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  & .landing-slide-in-left {
    opacity: 0;
    transform: translateX(-2000px);
    transition: 350ms;
  }

  & .landing-slide-in-left.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

`;

export default Landing;
